import { create } from 'zustand';

type TableHeaderLengthState = {
  length: number;
  setLength: (length: number) => void;
};

export const useTableHeaderLengthStore = create<TableHeaderLengthState>(
  (set) => ({
    length: 0,
    setLength: (length: number) => set({ length }),
  }),
);
