import React from 'react';
import { Button } from '../shared/Button/Button';

type Props = {
  error: Error;
  resetErrorBoundary: () => void;
};

export const ErrorBoundaryFallback = ({ error, resetErrorBoundary }: Props) => {
  console.error('ErrorBoundary caught an error:', error);

  return (
    <div className="py-2.5 text-center text-sm text-gray-500">
      <p>An error occurred while processing your request.</p>
      <p>Please try again or contact support.</p>
      <div className="py-2.5 text-center">
        <Button
          buttonSize="sm"
          type="button"
          variant="primary"
          onClick={resetErrorBoundary}
        >
          Try Again
        </Button>
      </div>
    </div>
  );
};
