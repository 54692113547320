import clsx from 'clsx';
import React, { ReactNode } from 'react';

type Props = {
  children: ReactNode;
  isFirstPaddingZero?: boolean;
  isBreakAll?: boolean;
  disabled?: boolean;
  showTooltipOnHover?: boolean;
  isPaddingZero?: boolean;
  textColor?: 'gray' | 'black' | 'red' | 'blue';
  isBold?: boolean;
};

export const TableBodyBasic = ({
  children,
  isFirstPaddingZero = false,
  isBreakAll = false,
  disabled = false,
  showTooltipOnHover = false,
  isPaddingZero = false,
  textColor = 'black',
  isBold = false,
}: Props) => {
  const textColorClass = {
    gray: 'text-gray-300',
    black: 'text-gray-900',
    red: 'text-red-500',
    blue: 'text-congress-blue',
  };

  return (
    <td
      className={clsx(
        'whitespace-pre-wrap px-3 text-sm',
        disabled && textColorClass.gray,
        showTooltipOnHover && 'group relative',
        isPaddingZero ? 'py-0' : 'py-4',
        !disabled && textColorClass[textColor],
        {
          'first:px-0': isFirstPaddingZero,
          'break-all': isBreakAll,
          'font-semibold': isBold,
        },
      )}
    >
      {children}
    </td>
  );
};
