import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorBoundaryFallback } from './ErrorBoundaryFallback';

type Props = {
  children: React.ReactNode;
};

export const ErrorBoundaryWrapper = ({ children }: Props) => {
  return (
    <ErrorBoundary fallbackRender={ErrorBoundaryFallback}>
      {children}
    </ErrorBoundary>
  );
};
