import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';

interface ListItem {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any; // 여기서는 간단히 모든 문자열 키에 대해 any 타입을 허용합니다.
}

export const useCheckbox = (
  lists: ListItem[],
  index: string,
  selectedIdxList?: number[],
) => {
  const [selectedArray, setSelectedArray] = useState<number[]>(
    selectedIdxList ? selectedIdxList : [],
  );
  const [isOpenedCautionDialog, setIsOpenedCautionDialog] = useState(false);
  const { pathname } = useLocation();

  const openDialog = () => {
    setIsOpenedCautionDialog(true);
  };

  const closeDialog = () => {
    setIsOpenedCautionDialog(false);
  };

  const resetSelectedArray = () => {
    setSelectedArray([]);
  };

  const selectAllArray = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (selectedArray.length > 0 && selectedArray.length < lists.length) {
      const allIdx = lists.map((item) => item[index]);
      setSelectedArray(allIdx);
      return;
    }

    const target = e.target;

    if (target.checked) {
      const allIdx = lists.map((item) => item[index]);
      setSelectedArray(allIdx);
    } else {
      setSelectedArray([]);
    }
  };

  const selectArray = (idx: number, e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target;
    if (target.checked) {
      setSelectedArray([...selectedArray, idx].sort((a, b) => a - b));
    } else {
      setSelectedArray(selectedArray.filter((item) => item !== idx));
    }
  };

  useEffect(() => {
    if (!selectedIdxList) {
      setSelectedArray([]);
    }
  }, [pathname]);

  return {
    isOpenedCautionDialog,
    setIsOpenedCautionDialog,
    openDialog,
    closeDialog,
    selectAllArray,
    selectArray,
    selectedArray,
    resetSelectedArray,
  };
};
