import clsx from 'clsx';
import React, { ButtonHTMLAttributes, ReactNode } from 'react';

export type Variant =
  | 'primary'
  | 'secondary'
  | 'caution'
  | 'black'
  | 'blue'
  | 'imageEscape'
  | 'imageBasic'
  | 'imageOriginal'
  | 'link'
  | 'search'
  | 'gray'
  | 'borderPrimary'
  | 'toggleActivate'
  | 'toggleDeactivate'
  | 'toggleOn'
  | 'toggleOff'
  | 'outlinedBlue';
export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  children: ReactNode;
  variant?: Variant;
  buttonSize?: 'none' | 'xs' | 'sm' | 'md' | 'lg';
  isSquare?: boolean;
  noRing?: boolean;
}

export const Button = ({
  children,
  className = '',
  variant = 'primary',
  buttonSize = 'md',
  isSquare = false,
  noRing = false,
  ...attributes
}: ButtonProps) => {
  const buttons = {
    primary:
      'enabled:bg-congress-blue enabled:hover:bg-allports disabled:bg-disabled enabled:text-white enabled:focus-visible:outline enabled:focus-visible:outline-2 enabled:focus-visible:outline-offset-2 enabled:focus-visible:outline-indigo-600 disabled:border disabled:text-gray-400 disabled:focus-visible:outline disabled:focus-visible:outline-2 disabled:focus-visible:outline-offset-2 disabled:focus-visible:outline-gray-500',
    secondary: clsx(
      'bg-white text-gray-900 enabled:hover:bg-gray-50 disabled:text-gray-400',
      { 'ring-1 ring-inset ring-gray-300': !noRing },
    ),
    caution:
      'inline-flex justify-center bg-red-600 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 enabled:hover:bg-red-500',
    black:
      'inline-flex items-center gap-x-1.5 bg-black text-white enabled:hover:opacity-70',
    blue: 'inline-flex justify-center bg-indigo-600  text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 enabled:hover:bg-indigo-500',
    imageBasic:
      'inline-flex justify-center focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 enabled:hover:bg-gray-200 enabled:hover:opacity-70 shadow-none',
    imageEscape:
      'inline-flex justify-center focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 enabled:hover:bg-red-600 enabled:hover:text-white shadow-none',
    imageOriginal: 'shadow-none enabled:hover:text-indigo-900',
    link: 'shadow-none text-indigo-600 enabled:hover:text-indigo-900',
    search:
      'inline-flex justify-center bg-gray-700  text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-700 disabled:hover:bg-gray-500',
    gray: 'inline-flex justify-center bg-gray-700 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-700 disabled:bg-gray-200',
    borderPrimary:
      'border-congress-blue text-congress-blue border border-solid bg-white hover:bg-gray-50 disabled:hover:bg-white',
    toggleActivate:
      'bg-white border border-blue-900 text-blue-900 enabled:hover:bg-blue-200/70 enabled:hover:text-blue-900/80 disabled:text-gray-300 disabled:border-gray-300',
    toggleDeactivate:
      'bg-white border border-red-500 text-red-500 enabled:hover:bg-red-100/70 enabled:hover:text-red-500/80 disabled:text-gray-300 disabled:border-gray-300',
    toggleOn:
      'bg-white border border-blue-900 text-blue-900 enabled:hover:bg-blue-200/70 enabled:hover:text-blue-900/80 disabled:text-gray-300 disabled:border-gray-300 w-11 py-1',
    toggleOff:
      'bg-white border border-red-500 text-red-500 enabled:hover:bg-red-100/70 enabled:hover:text-red-500/80 disabled:text-gray-300 disabled:border-gray-300 w-11 py-1',
    outlinedBlue:
      'bg-white border border-blue-900 text-blue-900 enabled:hover:bg-blue-200/70 enabled:hover:text-blue-900/80 disabled:text-gray-300 disabled:border-gray-300',
  };

  const size = {
    none: '',
    xs: 'px-2 py-1',
    sm: 'px-2.5 py-1.5',
    md: 'px-3 py-2',
    lg: 'px-4 py-3',
  };

  const squareStyle = isSquare
    ? 'flex-center size-9 rounded-md text-gray-900 shadow-sm'
    : '';

  return (
    <button
      className={clsx(
        'rounded-md text-sm font-semibold shadow',
        buttons[variant],
        size[buttonSize],
        squareStyle,
        className,
      )}
      {...attributes}
    >
      {children}
    </button>
  );
};
