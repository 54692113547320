import { SlashIcon } from '@heroicons/react/24/outline';
import React, { ReactNode } from 'react';

type SectionTitleHeaderDescriptionProps = {
  children: ReactNode;
};

const SectionTitleHeaderDescription = ({
  children,
}: SectionTitleHeaderDescriptionProps) => {
  return (
    <>
      <SlashIcon className="size-5 text-gray-500" />
      <p className="text-sm font-normal leading-tight text-gray-500">
        {children}
      </p>
    </>
  );
};

export default SectionTitleHeaderDescription;
