import React, { ReactNode } from 'react';

type SectionTitleHeaderProps = {
  children: ReactNode;
};

const SectionTitleHeader = ({ children }: SectionTitleHeaderProps) => {
  return (
    <h2 className="text-base font-semibold leading-normal text-gray-900">
      {children}
    </h2>
  );
};

export default SectionTitleHeader;
