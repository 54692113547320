import React from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';
import { ErrorBoundaryWrapper } from '../ErrorBoundary/ErrorBoundaryWrapper';

type Props = {
  page: number;
  numPages: number;
  isDashboard?: boolean;
};

export const PaginationRightButton = ({
  page,
  numPages,
  isDashboard = true,
}: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const updateNextPageInQuery = () => {
    const newParams = new URLSearchParams(searchParams);
    page === numPages
      ? newParams.set('page', String(numPages))
      : newParams.set('page', String(page + 1));
    setSearchParams(newParams);
  };

  const newSearchParams = new URLSearchParams(searchParams);
  page === numPages
    ? newSearchParams.set('page', String(numPages))
    : newSearchParams.set('page', String(page + 1));

  return (
    <ErrorBoundaryWrapper>
      {isDashboard ? (
        <Link
          to={{ search: newSearchParams.toString() }}
          className={clsx(
            'relative inline-flex items-center rounded-r-md p-2 text-gray-400 ring-1 ring-inset ring-gray-300 focus:z-20 focus:outline-offset-0',
            page === numPages ? 'cursor-default' : 'hover:bg-gray-50',
          )}
        >
          <span className="sr-only">Next</span>
          <ChevronRightIcon className="size-5" aria-hidden="true" />
        </Link>
      ) : (
        <button
          type="button"
          className={clsx(
            'relative inline-flex items-center rounded-r-md p-2 text-gray-400 ring-1 ring-inset ring-gray-300 focus:z-20 focus:outline-offset-0',
            page === numPages ? 'cursor-default' : 'hover:bg-gray-50',
          )}
          onClick={updateNextPageInQuery}
        >
          <span className="sr-only">Next</span>
          <ChevronRightIcon className="size-5" aria-hidden="true" />
        </button>
      )}
    </ErrorBoundaryWrapper>
  );
};
