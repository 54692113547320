import React from 'react';
import { Link } from 'react-router-dom';
import { ErrorBoundaryWrapper } from '../ErrorBoundary/ErrorBoundaryWrapper';

type Props = {
  page: number;
  numPages: number;
  pathname: string;
};

export const MobileSizePagination = ({ page, numPages, pathname }: Props) => {
  return (
    <ErrorBoundaryWrapper>
      <div className="flex flex-1 justify-between sm:hidden">
        <Link
          to={`${pathname}?page=${page !== 1 ? page - 1 : 1}`}
          className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
        >
          Previous
        </Link>
        <Link
          to={`${pathname}?page=${page !== numPages ? page + 1 : numPages}`}
          className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
        >
          Next
        </Link>
      </div>
    </ErrorBoundaryWrapper>
  );
};
