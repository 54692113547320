import clsx from 'clsx';
import React from 'react';

type SpinnerProps = {
  message?: string;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'full';
  color?: 'primary' | 'black' | 'gray' | 'red' | 'green' | 'yellow' | 'pink';
  text?: 'sm' | 'lg';
  isFullScreen?: boolean;
};

export const Spinner = ({
  message = '',
  size = 'md',
  color = 'primary',
  text = 'sm',
  isFullScreen = false,
}: SpinnerProps) => {
  const sizeMap = {
    xs: 'size-4',
    sm: 'size-8',
    md: 'size-12',
    lg: 'size-16',
    xl: 'size-20',
    full: 'size-36',
  };

  const colorMap = {
    primary: 'fill-congress-blue',
    black: 'fill-black',
    gray: 'fill-gray-600',
    red: 'fill-red-600',
    green: 'fill-green-600',
    yellow: 'fill-yellow-600',
    pink: 'fill-pink-600',
  };

  const textMap = {
    sm: 'text-sm',
    lg: 'text-lg',
  };

  const spinnerContent = (
    <div className="flex flex-col items-center justify-center gap-1">
      <svg
        width="80"
        height="80"
        aria-hidden="true"
        role="status"
        viewBox="0 0 80 80"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={clsx(
          'mr-2 inline animate-spin text-gray-200 dark:text-gray-600',
          sizeMap[size],
          colorMap[color],
        )}
      >
        <g id="loading" clipPath="url(#clip0_11599_5703)">
          <g id="Group">
            <path
              id="Vector"
              opacity="0.1"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M39.9999 13.3327C47.0724 13.3327 53.8551 16.1422 58.8561 21.1432C63.8571 26.1441 66.6666 32.9269 66.6666 39.9993C66.6666 47.0718 63.8571 53.8546 58.8561 58.8555C53.8551 63.8565 47.0724 66.666 39.9999 66.666C32.9275 66.666 26.1447 63.8565 21.1437 58.8555C16.1428 53.8546 13.3333 47.0718 13.3333 39.9993C13.3333 32.9269 16.1428 26.1441 21.1437 21.1432C26.1447 16.1422 32.9275 13.3327 39.9999 13.3327ZM73.3333 39.9993C73.3333 21.5893 58.4099 6.66602 39.9999 6.66602C21.5899 6.66602 6.66659 21.5893 6.66659 39.9993C6.66659 58.4093 21.5899 73.3327 39.9999 73.3327C58.4099 73.3327 73.3333 58.4093 73.3333 39.9993Z"
              fill="#374151"
            />
            <path
              id="Vector_2"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M39.9999 13.3327C46.8752 13.3182 53.4876 15.9737 58.4432 20.7394C58.7593 21.0423 59.1319 21.28 59.5398 21.4389C59.9477 21.5978 60.383 21.6748 60.8206 21.6656C61.2583 21.6563 61.6899 21.5609 62.0907 21.3848C62.4915 21.2087 62.8537 20.9554 63.1566 20.6394C63.4595 20.3233 63.6972 19.9507 63.8561 19.5428C64.015 19.1349 64.092 18.6997 64.0827 18.262C64.0735 17.8243 63.9781 17.3928 63.802 16.992C63.6259 16.5912 63.3726 16.229 63.0566 15.9261C56.8597 9.97083 48.5945 6.65137 39.9999 6.66606C39.1159 6.66606 38.268 7.01725 37.6429 7.64237C37.0178 8.2675 36.6666 9.11534 36.6666 9.9994C36.6666 10.8835 37.0178 11.7313 37.6429 12.3564C38.268 12.9815 39.1159 13.3327 39.9999 13.3327Z"
              fill="#043E8D"
            />
          </g>
        </g>
        <defs>
          <clipPath id="clip0_11599_5703">
            <rect width="80" height="80" fill="white" />
          </clipPath>
        </defs>
      </svg>
      <span className="sr-only">Loading...</span>
      {message && (
        <p
          className={clsx(
            'animate-pulse font-normal leading-loose tracking-tight',
            textMap[text],
          )}
        >
          {message}
        </p>
      )}
    </div>
  );

  if (isFullScreen) {
    return (
      <div className="absolute left-0 top-0 z-[9999] size-full backdrop-blur-sm">
        <div className="flex h-screen items-center justify-center">
          {spinnerContent}
        </div>
      </div>
    );
  }
  return spinnerContent;
};
