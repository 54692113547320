import clsx from 'clsx';
import React, { ReactNode } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { ErrorBoundaryWrapper } from '../ErrorBoundary/ErrorBoundaryWrapper';

type Props = {
  page: number;
  item: number | string;
  children: ReactNode;
  isDashboard?: boolean;
};

export const PaginationNumberButton = ({
  page,
  item,
  children,
  isDashboard = true,
}: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const updatePageInQuery = () => {
    const newParams = new URLSearchParams(searchParams);
    newParams.set('page', String(item));
    setSearchParams(newParams);
  };

  const newSearchParams = new URLSearchParams(searchParams);
  newSearchParams.set('page', String(item));

  return (
    <ErrorBoundaryWrapper>
      {isDashboard ? (
        <Link
          to={{ search: newSearchParams.toString() }}
          aria-current={page === item ? 'page' : null}
          className={clsx(
            'relative z-10 inline-flex items-center px-4 py-2 text-sm font-semibold focus:z-20',
            page === item
              ? 'bg-congress-blue text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
              : 'bg-white text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-offset-0',
          )}
        >
          {children}
        </Link>
      ) : (
        <button
          aria-current={page === item ? 'page' : null}
          className={clsx(
            'relative z-10 mx-4 inline-flex items-center px-4 py-2 text-sm font-semibold focus:z-20',
            page === item
              ? 'bg-congress-blue text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
              : 'bg-white text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-offset-0',
          )}
          onClick={updatePageInQuery}
        >
          {children}
        </button>
      )}
    </ErrorBoundaryWrapper>
  );
};
