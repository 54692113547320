import React from 'react';
import clsx from 'clsx';
import { UseFormRegisterReturn } from 'react-hook-form';
import { CheckIcon } from '@heroicons/react/20/solid';

type Props = {
  configuration: 'standard' | 'remote';
  register: UseFormRegisterReturn;
};

export const IdeMode = ({ configuration, register }: Props) => {
  const executionModes = [
    { id: 'standard', label: 'Standard' },
    { id: 'remote', label: 'Remote' },
  ];

  return (
    <>
      {executionModes.map((mode) => (
        <React.Fragment key={mode.id}>
          <input
            type="radio"
            id={mode.id}
            name="configuration"
            value={mode.id}
            className="peer hidden"
            {...register}
          />
          <label
            htmlFor={mode.id}
            className={clsx(
              'inline-flex w-52 cursor-pointer items-center justify-between rounded-lg border p-3.5 text-sm font-medium text-gray-500 hover:bg-gray-600 hover:text-gray-200',
              configuration === mode.id
                ? 'border-allports bg-allports text-white'
                : 'border-gray-200 bg-white',
            )}
          >
            {mode.label}
            <CheckIcon
              className={
                configuration === mode.id
                  ? 'visible size-5'
                  : 'invisible hidden'
              }
            />
          </label>
        </React.Fragment>
      ))}
    </>
  );
};
