import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
} from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';
import React from 'react';

interface Props<T> {
  query: string;
  setQuery: (query: string) => void;
  filteredList: T[] | undefined;
  findTitleFromIdx: (idx: number) => string | undefined;
  isLoading: boolean;
  isError: boolean;
  error: Error | null;
  value: number | string;
  onChange: (value: number) => void;
  valueToSave: string;
  valueToShow: string;
  placeholder?: string;
  disabled?: boolean;
  optionsDisabled?: boolean;
}

export const ComboboxUI = <T,>({
  query,
  setQuery,
  filteredList,
  findTitleFromIdx,
  isLoading,
  isError,
  error,
  value,
  onChange,
  placeholder,
  valueToSave,
  valueToShow,
  disabled = false,
  optionsDisabled = false,
}: Props<T>) => {
  return (
    <Combobox
      as="div"
      value={typeof value === 'number' ? value : Number(value)}
      onChange={onChange}
      onClose={() => setQuery('')}
      disabled={isLoading || disabled}
    >
      <div className="relative">
        <div className="flex w-full items-center justify-between gap-2">
          <div className="relative flex w-full items-center justify-between">
            <ComboboxInput
              className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              displayValue={(value: number | string) =>
                findTitleFromIdx(
                  typeof value === 'number' ? value : Number(value),
                ) || query
              }
              onChange={(e) => setQuery(e.target.value)}
              placeholder={placeholder}
              disabled={disabled}
            />
            <ComboboxButton className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
              <ChevronUpDownIcon
                className="size-5 text-gray-400"
                aria-hidden="true"
              />
            </ComboboxButton>
          </div>
        </div>
        <ComboboxOptions className="absolute z-10 mt-1 max-h-60 w-[calc(100%-0.1rem)] overflow-y-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 scrollbar-thin focus:outline-none sm:text-sm">
          {isError ? (
            <div className="relative cursor-default select-none px-4 py-2 text-red-600">
              {error.message}
            </div>
          ) : filteredList.length === 0 ? (
            <div className="relative cursor-default select-none px-4 py-2 text-gray-700">
              Nothing found
            </div>
          ) : (
            filteredList.map((value, index) => (
              <ComboboxOption
                key={index}
                className={clsx(
                  'group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-indigo-600 data-[disabled]:text-gray-300 data-[focus]:text-white',
                )}
                value={value[valueToSave]}
                disabled={optionsDisabled}
              >
                <>
                  <span
                    className={clsx(
                      'break-all group-data-[selected]:font-semibold',
                    )}
                  >
                    {value[valueToShow]}
                  </span>
                  <span
                    className={clsx(
                      'group absolute inset-y-0 right-0 flex items-center pr-4 text-indigo-600 data-[focus]:text-white ',
                    )}
                  >
                    <CheckIcon
                      className="invisible size-5 group-data-[selected]:visible group-data-[focus]:text-white"
                      aria-hidden="true"
                    />
                  </span>
                </>
              </ComboboxOption>
            ))
          )}
        </ComboboxOptions>
      </div>
    </Combobox>
  );
};
