import React from 'react';
import { ErrorBoundaryWrapper } from '../ErrorBoundary/ErrorBoundaryWrapper';
import { MailReceiver } from '../../utils/type';
import clsx from 'clsx';

type Props = {
  state: MailReceiver[];
  setState: React.Dispatch<React.SetStateAction<MailReceiver[]>>;
  isRunning?: boolean;
};

export const EmailList = ({ state, setState, isRunning = true }: Props) => {
  const deleteTagItem = (indexToDelete: number) => {
    setState(state?.filter((_, index) => index !== indexToDelete));
  };

  return (
    <div className="mt-4">
      <ErrorBoundaryWrapper>
        {state?.map((item, index) => (
          <span
            key={item.email}
            className="m-1 inline-flex items-center justify-between gap-x-0.5 rounded-md bg-gray-100 px-2 py-1 text-xs font-medium text-gray-600"
          >
            {item?.name === null
              ? item?.email
              : `${item?.email}(${item?.name})`}
            {isRunning && (
              <button
                type="button"
                className={clsx(
                  'group relative -mr-1 size-3.5 rounded-sm hover:bg-gray-500/20',
                  item.systemRole === 'Admin' && 'hidden',
                )}
                onClick={() => deleteTagItem(index)}
              >
                <span className="sr-only">Remove</span>
                <svg
                  viewBox="0 0 14 14"
                  className="size-3.5 stroke-gray-700/50 group-hover:stroke-gray-700/75"
                >
                  <path d="M4 4l6 6m0-6l-6 6" />
                </svg>
                <span className="absolute -inset-1" />
              </button>
            )}
          </span>
        ))}
      </ErrorBoundaryWrapper>
    </div>
  );
};
