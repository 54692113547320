import { useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

type Props = {
  dependencyArray?: unknown;
  offsetY: number;
};

export const useDetectScrollLocation = ({
  dependencyArray,
  offsetY,
}: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const elementRefs = useRef<HTMLHeadingElement[]>([]);
  const [activeSection, setActiveSection] = useState('');

  useEffect(() => {
    const { hash } = location;
    const targetElement = elementRefs?.current?.find(
      (ref) => `${ref?.id}` === decodeURI(hash.slice(1)),
    );
    const y =
      targetElement?.getBoundingClientRect().top + window.scrollY + offsetY;

    if (targetElement) {
      window.scrollTo({
        top: y,
      });
    }
  }, [location, dependencyArray ?? null]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY - offsetY;

      const activeElement = elementRefs.current.find(
        (ref) =>
          ref?.offsetTop <= scrollPosition &&
          ref?.offsetTop + ref?.offsetHeight > scrollPosition,
      );

      if (activeElement && activeElement?.id !== activeSection) {
        setActiveSection(activeElement?.id);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [activeSection]);

  const handleClick = (item: string) => {
    const targetElement = elementRefs.current.find((ref) => ref.id === item);
    const y =
      targetElement?.getBoundingClientRect().top + window.scrollY + offsetY;

    if (targetElement) {
      window.scrollTo({
        top: y,
      });

      setActiveSection(item);
      navigate(`${location.search}#${item}`);
    }
  };

  return { elementRefs, activeSection, handleClick };
};
