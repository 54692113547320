import React, { ReactElement } from 'react';
import {
  ChevronRightIcon,
  EllipsisHorizontalIcon,
} from '@heroicons/react/20/solid';
import { useNavigate } from 'react-router-dom';
import { Button } from '../Button/Button';
import {
  Breadcrumbs,
  PageHeadingButtons,
  TestResultDetailMenu,
} from '../../../utils/type';
import { TooltipTop } from '../Tooltip/TooltipTop';
import { LinkButton } from '../Button/LinkButton';
import clsx from 'clsx';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { ErrorBoundaryWrapper } from '@autosquare/common';

type Props = {
  breadcrumbs?: Breadcrumbs[];
  pageTitle: string;
  titleDescription?: string;
  searchBar?: ReactElement;
  pageHeadingButtons?: PageHeadingButtons[];
  url?: string;
  testResultDetailMenu?: TestResultDetailMenu[];
  copy?: boolean;
};

export const PagingHeadingsNavigate = ({
  breadcrumbs,
  titleDescription,
  pageTitle,
  searchBar,
  pageHeadingButtons,
  url,
  testResultDetailMenu,
  copy = false,
}: Props) => {
  const navigate = useNavigate();

  return (
    <ErrorBoundaryWrapper>
      <div>
        {breadcrumbs && (
          <div>
            <nav className="hidden sm:flex" aria-label="Breadcrumb">
              <ol className="flex items-center space-x-4">
                {breadcrumbs.map((breadcrumb, bIndex) => (
                  <li key={bIndex}>
                    <div className="flex">
                      {breadcrumb.href ? (
                        <button
                          type="button"
                          className={clsx(
                            'text-sm font-medium text-gray-500',
                            bIndex < breadcrumbs.length - 1 && 'mr-4',
                            !breadcrumb.disabled && 'hover:text-gray-700',
                          )}
                          onClick={() =>
                            navigate(breadcrumb.href, {
                              state: breadcrumb.state,
                            })
                          }
                          disabled={breadcrumb.disabled}
                        >
                          {breadcrumb.title}
                        </button>
                      ) : (
                        <p
                          className={clsx(
                            'text-sm font-medium text-gray-500',
                            bIndex < breadcrumbs.length - 1 && 'mr-4',
                          )}
                        >
                          {breadcrumb.title}
                        </p>
                      )}
                      {bIndex < breadcrumbs.length - 1 && (
                        <ChevronRightIcon
                          className="size-5 shrink-0 text-gray-400"
                          aria-hidden="true"
                        />
                      )}
                    </div>
                  </li>
                ))}
              </ol>
            </nav>
          </div>
        )}
        <div className="mt-2 md:flex md:items-center md:justify-between">
          <div
            className={clsx(
              'min-w-0',
              copy && 'flex items-center justify-center gap-3',
            )}
          >
            <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
              {pageTitle}
            </h2>
            {titleDescription && (
              <p className="mt-2 text-sm text-gray-500">{titleDescription}</p>
            )}
            {copy && <LinkButton url={url} />}
          </div>
          <div className="mt-4 flex shrink-0 gap-2 md:ml-4 md:mt-0">
            {searchBar}
            {pageHeadingButtons?.map((item, index) => (
              <div key={index} className="group relative">
                {item.description && (
                  <TooltipTop
                    positionClass={clsx(
                      'icon-description invisible after:left-1/2 group-hover:visible',
                      item.description.length > 12 &&
                        item.description.length <= 15 &&
                        '-right-7',
                      item.description.length > 15 &&
                        item.description.length <= 20 &&
                        '-right-11',
                      item.description.length > 20 && '-right-7',
                    )}
                  >
                    {item.description}
                  </TooltipTop>
                )}
                <Button
                  type={item.type}
                  variant={item.variant}
                  onClick={item.onClick}
                  id={item.id}
                  form={item.form}
                  disabled={item.disabled}
                >
                  {item?.title ?? item?.icon}
                </Button>
              </div>
            ))}
            {testResultDetailMenu && (
              <Menu>
                <MenuButton className="ml-4">
                  <EllipsisHorizontalIcon className="size-6" />
                </MenuButton>
                <MenuItems
                  anchor="bottom end"
                  className="z-20 mt-1 flex flex-col rounded border border-solid border-gray-200 bg-white shadow"
                >
                  {testResultDetailMenu.map((item) => (
                    <MenuItem key={item.title} disabled={item.disabled}>
                      <button
                        className="px-3 py-2 text-sm font-normal leading-normal text-black data-[focus]:bg-indigo-600 data-[disabled]:text-gray-300 data-[focus]:text-white"
                        type="button"
                        onClick={item.onClick}
                        disabled={item.disabled}
                      >
                        {item.title}
                      </button>
                    </MenuItem>
                  ))}
                </MenuItems>
              </Menu>
            )}
          </div>
        </div>
      </div>
    </ErrorBoundaryWrapper>
  );
};
