import React from 'react';
import { ErrorBoundaryWrapper } from '../ErrorBoundary/ErrorBoundaryWrapper';

import { HeaderContainer } from './HeaderContainer';
import { HeaderTitle } from './HeaderTitle';
import { LocationFirstHeader } from './LocationFirstHeader';
import { LocationHeader } from './LocationHeader';
import { LocationHeaderList } from './LocationHeaderList';

type Props = {
  firstHeader: string;
  headers?: string[];
  headerTitle: string;
  rightElement?: React.ReactNode;
};

export const MainHeader = ({
  firstHeader,
  headers,
  headerTitle,
  rightElement,
}: Props) => {
  return (
    <ErrorBoundaryWrapper>
      <LocationHeader>
        <LocationFirstHeader>{firstHeader}</LocationFirstHeader>
        {headers?.map((headerList) => (
          <LocationHeaderList key={headerList}>{headerList}</LocationHeaderList>
        ))}
      </LocationHeader>
      <HeaderContainer>
        <div className="flex w-full items-center justify-between">
          <HeaderTitle>{headerTitle}</HeaderTitle>
          {rightElement}
        </div>
      </HeaderContainer>
    </ErrorBoundaryWrapper>
  );
};
