import React from 'react';
import { useLocation } from 'react-router-dom';
import { MobileSizePagination } from './MobileSizePagination';
import { OverSevenPages } from './OverSevenPages';
import { PaginationLeftButton } from './PaginationLeftButton';
import { PaginationNumberButton } from './PaginationNumberButton';
import { PaginationRightButton } from './PaginationRightButton';
import clsx from 'clsx';
import { ErrorBoundaryWrapper } from '../ErrorBoundary/ErrorBoundaryWrapper';

type Props = {
  page: number;
  total: number;
  size: number;
  isDashboard?: boolean;
};

export const Pagination = ({
  page,
  total,
  size,
  isDashboard = false,
}: Props) => {
  const { pathname } = useLocation();
  const isUiTestLocation = pathname.includes('ui-test');

  const numPages = isNaN(total) ? 1 : Math.ceil(total / size);

  return (
    <ErrorBoundaryWrapper>
      <div
        className={clsx(
          'flex items-center justify-between bg-white py-3',
          isUiTestLocation && 'border-t border-gray-200',
        )}
      >
        <MobileSizePagination
          page={page}
          numPages={numPages}
          pathname={pathname}
        />
        <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-end">
          <div>
            <div
              className="isolate inline-flex -space-x-px rounded-md shadow-sm"
              aria-label="Pagination"
            >
              <PaginationLeftButton page={page} isDashboard={isDashboard} />
              {numPages <= 7 ? (
                Array(numPages)
                  ?.fill(undefined)
                  ?.map((_, i) => (
                    <PaginationNumberButton
                      key={i}
                      page={page}
                      item={i + 1}
                      isDashboard={isDashboard}
                    >
                      {i + 1}
                    </PaginationNumberButton>
                  ))
              ) : (
                <OverSevenPages page={page} numPages={numPages} />
              )}
              <PaginationRightButton
                page={page}
                numPages={numPages}
                isDashboard={isDashboard}
              />
            </div>
          </div>
        </div>
      </div>
    </ErrorBoundaryWrapper>
  );
};
