import clsx from 'clsx';
import React, { ReactNode } from 'react';

type Props = {
  children: ReactNode;
  width?: string;
};

export const SpeedTestResultTableHeader = ({ children, width }: Props) => {
  return (
    <th
      className={clsx(
        'whitespace-nowrap px-3 py-2 text-left text-sm font-semibold text-gray-900',
        width,
      )}
    >
      {children}
    </th>
  );
};
