import clsx from 'clsx';
import React from 'react';

interface Props {
  testCategory: 'ui' | 'speed';
  platform: 'mobile' | 'web';
}

export const TestCaseDetailSkeleton = ({ testCategory, platform }: Props) => {
  const topDescriptionList: string[] =
    testCategory === 'ui'
      ? platform === 'mobile'
        ? ['Platform Type', 'Priority', 'Test Group', 'Creator']
        : ['Priority', 'Test Group', 'Creator']
      : platform === 'mobile'
        ? [
            'Platform Type',
            'Priority',
            'Test Group',
            'Service Group',
            'Creator',
          ]
        : ['Priority', 'Test Group', 'Service Group', 'Creator'];

  const bottomDescriptionList = ['TC-ID', 'Title', 'Description'];

  const descriptionListLength = topDescriptionList.length;
  const gridCols =
    descriptionListLength === 3
      ? 'sm:grid-cols-3'
      : descriptionListLength === 4
        ? 'sm:grid-cols-4'
        : descriptionListLength === 5 && 'sm:grid-cols-5';

  return (
    <div className="mt-1">
      <dl
        className={clsx(
          'grid grid-cols-1 border-t border-gray-100 py-6',
          gridCols,
        )}
      >
        {topDescriptionList.map((description) => (
          <div key={description}>
            <dt className="mb-4 text-sm font-medium leading-6 text-gray-900 ">
              {description}
            </dt>
            <dd className="mt-15 h-4 w-24 animate-pulse rounded-md bg-gray-300"></dd>
          </div>
        ))}
      </dl>
      <dl className="grid grid-cols-1 border-t border-gray-100 py-6 sm:grid-cols-3">
        {bottomDescriptionList.map((description) => (
          <div key={description}>
            <dt className="mb-4 text-sm font-medium leading-6 text-gray-900 ">
              {description}
            </dt>
            <dd className="mt-15 h-4 w-24 animate-pulse rounded-md bg-gray-300"></dd>
          </div>
        ))}
      </dl>
    </div>
  );
};
