import React from 'react';

import { childrenNode } from '../../utils/type';

export const TableContainer = ({ children }: childrenNode) => {
  return (
    <div className="mt-6 flow-root">
      <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <table className="min-w-full divide-y divide-gray-300">
            {children}
          </table>
        </div>
      </div>
    </div>
  );
};
