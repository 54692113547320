import clsx from 'clsx';
import React from 'react';
import { ErrorBoundaryWrapper } from '../ErrorBoundary/ErrorBoundaryWrapper';

type Props = {
  isRefetching?: boolean;
};

export const SpeedTestResultDetailSkeleton = ({ isRefetching }: Props) => {
  return (
    <ErrorBoundaryWrapper>
      <div className={clsx(!isRefetching && 'mt-14 px-8')}>
        {!isRefetching && (
          <>
            <div className="mb-6 flex items-center justify-between">
              <div className="h-8 w-64 animate-pulse rounded-md bg-gray-300"></div>
              <div className="h-8 w-32 animate-pulse rounded-md bg-gray-300"></div>
            </div>
            <div className="pb-4">
              <div className="pb-3 pt-6 text-sm text-gray-900">
                <div className="h-6 w-36 animate-pulse rounded bg-gray-300"></div>
              </div>
              <table className="min-w-full bg-white">
                <thead className="bg-gray-100">
                  <tr>
                    {Array.from({ length: 6 }).map((_, i) => (
                      <th
                        key={i}
                        className="h-10 animate-pulse rounded-md bg-gray-200 px-4 py-2"
                      ></th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {Array.from({ length: 1 }).map((_, rowIndex) => (
                    <tr key={rowIndex} className="h-[3.625rem]">
                      {Array.from({ length: 6 }).map((_, colIndex) => (
                        <td
                          key={colIndex}
                          className="animate-pulse bg-gray-50 px-4 py-2"
                        >
                          <div className="h-10 w-40 rounded bg-gray-300"></div>
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )}
        <div className="pb-4">
          <div className="flex items-center justify-between pb-3 pt-6">
            <div className="h-6 w-40 animate-pulse rounded-md bg-gray-300"></div>
            <div className="h-7 w-8 animate-pulse rounded-md bg-gray-300"></div>
          </div>
          <div className="h-56 w-full animate-pulse rounded-md bg-gray-100"></div>
        </div>
        <div className="pb-3 pt-6">
          <div className="h-96 w-full animate-pulse rounded-md bg-gray-100"></div>
        </div>
      </div>
    </ErrorBoundaryWrapper>
  );
};
