import React from 'react';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { useNavigate } from 'react-router-dom';

import { Breadcrumbs, PageHeadingButtons } from '../../../utils/type';
import { Button } from '../Button/Button';
import clsx from 'clsx';

type Props = {
  breadcrumbs: Breadcrumbs[];
  pageTitle: string;
  pageHeadingButtons?: PageHeadingButtons[];
  count?: number;
};

export const PagingHeadingButtonOnClick = ({
  breadcrumbs,
  pageTitle,
  pageHeadingButtons,
  count,
}: Props) => {
  const navigate = useNavigate();

  return (
    <div>
      <div>
        <nav className="hidden sm:flex" aria-label="Breadcrumb">
          <ol className="flex items-center space-x-4">
            {breadcrumbs?.map((breadcrumb, bIndex) => (
              <li key={breadcrumb?.title}>
                <div className="flex">
                  {breadcrumb.href ? (
                    <button
                      type="button"
                      className={`${
                        bIndex < breadcrumbs.length - 1 && 'mr-4'
                      } text-sm font-medium text-gray-500 hover:text-gray-700`}
                      onClick={() => navigate(breadcrumb?.href)}
                    >
                      {breadcrumb?.title}
                    </button>
                  ) : (
                    <p
                      className={`${
                        bIndex < breadcrumbs.length - 1 && 'mr-4'
                      } text-sm font-medium text-gray-500`}
                    >
                      {breadcrumb.title}
                    </p>
                  )}
                  {bIndex < breadcrumbs?.length - 1 && (
                    <ChevronRightIcon
                      className="size-5 shrink-0 text-gray-400"
                      aria-hidden="true"
                    />
                  )}
                </div>
              </li>
            ))}
          </ol>
        </nav>
      </div>
      <div className="mt-2 md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
            {pageTitle}
          </h2>
        </div>
        <div
          className={clsx('mt-4 flex shrink-0 gap-2 md:ml-4 md:mt-0', {
            'items-center justify-center gap-x-7': typeof count === 'number',
          })}
        >
          {typeof count === 'number' && (
            <div className="flex gap-x-2 font-semibold">
              <p className="text-base font-semibold">Total</p>
              <p className="text-base font-semibold">{count}</p>
            </div>
          )}
          {pageHeadingButtons?.map((item, index) => (
            <Button
              type={item.type ?? 'button'}
              key={index}
              onClick={item.onClick}
              variant={item.variant ?? 'primary'}
              disabled={item.disabled}
            >
              {item.title}
            </Button>
          ))}
        </div>
      </div>
    </div>
  );
};
