import React, { ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

export const TableRow = ({ children }: Props) => {
  return (
    <tr className="border-y-solid border-y border-y-gray-200 last:border-y-0 even:bg-gray-50">
      {children}
    </tr>
  );
};
