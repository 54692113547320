import clsx from 'clsx';
import React, { ReactNode } from 'react';

type SectionTitleContainerProps = {
  children: ReactNode;
};

const SectionTitleContainer = ({ children }: SectionTitleContainerProps) => {
  return (
    <div
      className={clsx('mb-3 mt-6 flex items-center justify-between text-sm')}
    >
      {children}
    </div>
  );
};

export default SectionTitleContainer;
