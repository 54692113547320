import React, { ReactNode } from 'react';

type SectionTitleHeaderContainerProps = {
  children: ReactNode;
};

const SectionTitleHeaderContainer = ({
  children,
}: SectionTitleHeaderContainerProps) => {
  return <div className="flex items-center gap-2">{children}</div>;
};

export default SectionTitleHeaderContainer;
