import React from 'react';

export const TableSkeleton = () => {
  return (
    <div
      className="w-full animate-pulse"
      data-testid="table-skeleton"
      aria-label="table-skeleton"
    >
      <div className="mb-6 mt-3 h-4 rounded bg-gray-200"></div>
      <div className="mb-6 h-4 rounded bg-gray-300"></div>
      <div className="mb-6 h-4 rounded bg-gray-200"></div>
      <div className="mb-6 h-4 rounded bg-gray-300"></div>
      <div className="mb-6 h-4 rounded bg-gray-200"></div>
    </div>
  );
};
