import React from 'react';
import { ErrorBoundaryWrapper } from '../ErrorBoundary/ErrorBoundaryWrapper';

import { PaginationDotDotDot } from './PaginationDotDotDot';
import { PaginationNumberButton } from './PaginationNumberButton';

type Props = {
  page: number;
  numPages: number;
};

export const OverSevenPages = ({ page, numPages }: Props) => {
  const selectedBothSide = [
    1,
    2,
    3,
    '...',
    numPages - 2,
    numPages - 1,
    numPages,
  ];

  const selectedCenterNumber = [
    1,
    '...',
    page - 1,
    page,
    page + 1,
    '...',
    numPages,
  ];

  return (
    <ErrorBoundaryWrapper>
      {page < 3 || page > numPages - 2
        ? selectedBothSide?.map((item, idx) =>
            item === '...' ? (
              <PaginationDotDotDot key={idx}>{item}</PaginationDotDotDot>
            ) : (
              <PaginationNumberButton
                key={idx}
                page={page}
                item={item}
                isDashboard={false}
              >
                {item}
              </PaginationNumberButton>
            ),
          )
        : selectedCenterNumber?.map((item, idx) =>
            item === '...' ? (
              <PaginationDotDotDot key={idx}>{item}</PaginationDotDotDot>
            ) : (
              <PaginationNumberButton
                key={idx}
                page={page}
                item={item}
                isDashboard={false}
              >
                {item}
              </PaginationNumberButton>
            ),
          )}
    </ErrorBoundaryWrapper>
  );
};
