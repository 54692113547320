import {
  useState,
  useCallback,
  SetStateAction,
  Dispatch,
  ChangeEvent,
} from 'react';

export const useInput = <T>(
  initialForm: T,
): [
  T,
  (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
  Dispatch<SetStateAction<T>>,
] => {
  const [form, setForm] = useState<T>(initialForm);

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value =
        e.target.type === 'checkbox' ? e.target.checked : e.target.value;
      setForm(value as T);
    },
    [form],
  );

  return [form, onChange, setForm];
};
