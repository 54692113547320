import React from 'react';

export const IdeModeHeader = () => {
  return (
    <>
      <h2 className="text-base font-semibold leading-7 text-gray-900">
        IDE Mode
      </h2>
      <p className="mt-1 text-sm leading-6 text-gray-600">
        PC에서 바로 사용할 수 있는 Standard 모드와 다른 PC의 IDE로 원격 접속
        가능한 Remote 모드 중에서 선택하세요.
      </p>
    </>
  );
};
