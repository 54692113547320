import React from 'react';
import { ErrorBoundaryWrapper } from '../ErrorBoundary/ErrorBoundaryWrapper';
import { useSearchParams } from 'react-router-dom';

type Props = {
  totalResults: number;
};

const ResultsToolbar = ({ totalResults }: Props) => {
  if (totalResults === 0) return null;

  const [searchParams] = useSearchParams();
  const page = Number(searchParams.get('page'));
  const size = Number(searchParams.get('size'));

  const totalPages = isNaN(totalResults) ? 1 : Math.ceil(totalResults / size);

  return (
    <ErrorBoundaryWrapper>
      <div className="-mb-6 border-b border-gray-300 pb-3">
        <p className="text-sm text-gray-700">
          Showing <span className="font-medium">{page} </span> to
          <span className="font-medium"> {totalPages} </span> of
          <span className="font-medium"> {totalResults}</span> results
        </p>
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default ResultsToolbar;
