import clsx from 'clsx';
import React, { ReactNode } from 'react';

// 타이틀 컨테이너
type SectionTitleContainerProps = {
  children: ReactNode;
};

export const SectionMainTitleContainer = ({
  children,
}: SectionTitleContainerProps) => {
  return <div className="flex items-center justify-between">{children}</div>;
};

// h2 태그 타이틀
type SectionTitleMainProps = {
  children: ReactNode;
  color?: 'primary' | 'black';
  fontSize?: 'base' | 'sm';
};

export const SectionTitleMain = ({
  children,
  color = 'black',
  fontSize = 'base',
}: SectionTitleMainProps) => {
  const colorClass = () => {
    if (color === 'black') {
      return 'text-gray-900';
    }
    if (color === 'primary') {
      return 'text-congress-blue';
    }
  };

  const fontSizeClass = () => {
    if (fontSize === 'base') {
      return 'text-base leading-7';
    }
    if (fontSize === 'sm') {
      return 'text-sm leading-normal';
    }
  };

  return (
    <h2 className={clsx('font-semibold', colorClass(), fontSizeClass())}>
      {children}
    </h2>
  );
};

// 타이틀 오른쪽 문구
type SectionTitleRightProps = {
  children: ReactNode;
  type?: 'caution' | 'default';
};

export const SectionTitleRight = ({
  children,
  type = 'default',
}: SectionTitleRightProps) => {
  return (
    <p
      className={clsx(
        'text-sm font-medium leading-tight',
        type === 'default' && 'text-gray-900',
        type === 'caution' && 'text-red-500',
      )}
    >
      {children}
    </p>
  );
};

// 설명 문구 (h2태그 밑)
type SectionTitleDescriptionProps = {
  children: ReactNode;
};

export const SectionTitleDescription = ({
  children,
}: SectionTitleDescriptionProps) => {
  return <p className="mt-1 text-sm text-gray-500">{children}</p>;
};
