import clsx from 'clsx';
import React, { InputHTMLAttributes, forwardRef } from 'react';
import { ErrorBoundaryWrapper } from '../../ErrorBoundary/ErrorBoundaryWrapper';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  color?: 'autosquare' | 'blue';
  checkboxSize?: 'sm' | 'md' | 'lg';
  customStyle?: string;
}

export const Checkbox = forwardRef<HTMLInputElement, Props>(
  (
    { customStyle, color = 'autosquare', checkboxSize = 'md', ...attribute },
    ref,
  ) => {
    const inputColor = {
      autosquare: 'text-congress-blue focus:ring-congress-blue',
      blue: 'text-indigo-600 focus:ring-indigo-600',
    };

    const inputSize = {
      xs: 'w-3 h-3',
      sm: 'w-3.5 h-3.5',
      md: 'w-4 h-4',
      lg: 'w-5 h-5',
    };

    return (
      <ErrorBoundaryWrapper>
        <input
          type="checkbox"
          className={clsx(
            'flex items-center justify-center rounded border border-solid border-gray-300 transition delay-75 duration-75 ease-in',
            inputSize[checkboxSize],
            inputColor[color],
            customStyle,
            attribute.disabled && 'bg-gray-100',
          )}
          ref={ref}
          {...attribute}
        />
      </ErrorBoundaryWrapper>
    );
  },
);

Checkbox.displayName = 'Checkbox';
