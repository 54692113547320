import clsx from 'clsx';
import React, { ReactNode } from 'react';

import { Button } from '../Button/Button';

interface Props {
  selectedArray?: unknown[];
  children: ReactNode;
  openDialog: () => void;
  isStepList?: boolean;
  isMarginTop?: boolean;
}

export const TableWrapper = ({
  selectedArray,
  openDialog,
  children,
  isStepList = false,
  isMarginTop = true,
}: Props) => {
  return (
    <div className={clsx('flow-root', isMarginTop ? 'mt-8' : 'mt-0')}>
      <div className="-mx-4 -my-2 overflow-x-auto scrollbar-thin sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          {selectedArray ? (
            <div className="relative">
              {selectedArray.length > 0 && (
                <div
                  className={clsx(
                    'div-delete-all 2xl:left-20',
                    isStepList ? 'left-16 ml-2' : 'left-12',
                  )}
                >
                  <Button
                    type="button"
                    variant="secondary"
                    aria-label="Delete All"
                    buttonSize="xs"
                    onClick={openDialog}
                  >
                    Delete
                  </Button>
                </div>
              )}
            </div>
          ) : null}
          <table className="h-full min-w-full divide-y divide-gray-300">
            {children}
          </table>
        </div>
      </div>
    </div>
  );
};
