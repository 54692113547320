import clsx from 'clsx';
import React, { ReactNode } from 'react';

type SectionTitleProps = {
  children: ReactNode;
  withBorder?: boolean;
};

// header 바로 밑의 Section Title 공용 컴포넌트
const SectionTitle = ({ children, withBorder = false }: SectionTitleProps) => {
  return (
    <div
      className={clsx(
        'flex flex-col justify-between pb-2',
        withBorder && 'border-b border-solid border-gray-200',
      )}
    >
      {children}
    </div>
  );
};

export default SectionTitle;
