import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';
import React, { ReactNode } from 'react';
import { useSearchParams } from 'react-router-dom';

type Props = {
  children: ReactNode;
  isFirstPaddingZero?: boolean;
  color?: string;
  isSortable?: boolean;
};

export const TableHeader = ({
  children,
  isFirstPaddingZero = false,
  color = 'text-gray-900',
  isSortable = false,
}: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const order = searchParams.get('order');

  const toggleOrder = () => {
    if (isSortable) {
      const currentOrder =
        searchParams.get('order') === 'desc' ? 'asc' : 'desc';
      setSearchParams({
        ...Object.fromEntries(searchParams),
        order: currentOrder,
      });
    }
  };

  return (
    <th
      scope="col"
      className={clsx(
        'whitespace-nowrap px-3 py-3.5 text-left text-sm font-semibold',
        color,
        { 'first:pl-0': isFirstPaddingZero },
        { 'flex cursor-pointer items-center gap-1': isSortable },
      )}
      onClick={toggleOrder}
    >
      {children}
      {isSortable && order === 'desc' ? (
        <ChevronDownIcon className="size-5 text-gray-400" strokeWidth={2.5} />
      ) : (
        isSortable &&
        order === 'asc' && (
          <ChevronUpIcon className="size-5 text-gray-400" strokeWidth={2.5} />
        )
      )}
    </th>
  );
};
