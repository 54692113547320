import { ButtonHTMLAttributes, MutableRefObject, ReactNode } from 'react';
import { Variant } from '../components/shared/Button/Button';
import { z } from 'zod';

export type childrenNode = {
  children: ReactNode;
};

export type ProjectType = {
  id: number;
  attributes: {
    name: string;
    description?: string | null;
    createdAt: Date;
    updatedAt: Date;
  };
};

export type ProjectLogsType = {
  id?: number;
  header?: string;
  upload_time?: string;
  result?: string;
};

export type ProjectListsType = { name: string; description?: string };

export type PrType = {
  idx: number;
  role: 0 | 1;
};

export enum RoleAuth {
  Admin = 'ROLE_ADMIN',
  User = 'ROLE_USER',
}

export type JwtDecodeType = {
  sub: string;
  auth: RoleAuth;
  pr: PrType[];
  exp: number;
};

export type UserInfoType = {
  idx: number;
  userId: string;
  name: string;
  status: string;
};

export type Breadcrumbs = {
  title: string;
  href?: string;
  disabled?: boolean;
  state?: { status: string; page: number };
};

export type PageHeadingButtons = {
  title?: string | ReactNode;
  href?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  type?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
  variant?: Variant;
  disabled?: boolean;
  description?: string;
  icon?: ReactNode;
  id?: string;
  form?: string;
};

export type TestResultDetailMenu = {
  title: string;
  onClick: () => void;
  disabled?: boolean;
};

export type ColSpanFullInputType = {
  labelName?: string;
  id?: string;
  placeHolder?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  pattern?: string;
  title?: string;
  min?: number;
  max?: number;
  minLength?: number;
  maxLength?: number;
  value?: string | number | null;
  defaultValue?: string | number | null;
  disabled?: boolean;
  required?: boolean;
};

export type OverviewFunctionalResultType = {
  idx: number;
  tester: string;
  scenarioName: string;
  startTime: string;
  result: 'Pass' | 'Fail' | 'Running' | 'N/A';
};

export interface UseCheckboxAttribute {
  selectAllArray: (e: React.ChangeEvent<HTMLInputElement>) => void;
  selectArray: (idx: number, e: React.ChangeEvent<HTMLInputElement>) => void;
  selectedArray: number[];
  openDialog: () => void;
  inputRef?: MutableRefObject<HTMLInputElement>;
}

export type DeepKeys<T> = T extends object
  ? {
      [K in keyof T]-?: K extends string
        ? T[K] extends Array<infer U>
          ? `${K}` | `${K}.${number}` | `${K}.${number}.${DeepKeys<U>}`
          : T[K] extends object
            ? `${K}` | `${K}.${DeepKeys<T[K]>}`
            : `${K}`
        : never;
    }[keyof T]
  : '';

export type DeepKeysWithUndefined<T> = T extends undefined ? '' : DeepKeys<T>;

export type TestTypeCategory = 'step' | 'case' | 'scenario';

export interface ElectronFile extends File {
  path?: string;
}

export const MailReceiverSchema = z.object({
  userIdx: z.number().optional(),
  email: z.string().email(),
  name: z.string().nullable(),
  systemRole: z.union([z.literal('Admin'), z.literal('Member')]).optional(),
});

export type MailReceiver = z.infer<typeof MailReceiverSchema>;
