import React from 'react';
import { Switch } from '@headlessui/react';
import clsx from 'clsx';

type Props = {
  isEnabled: boolean | null;
  setIsEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  disabled?: boolean;
};

export const ToggledSwitch = ({ isEnabled, setIsEnabled, disabled }: Props) => {
  return typeof isEnabled === 'boolean' ? (
    <Switch
      checked={typeof isEnabled === 'boolean' && isEnabled}
      onChange={setIsEnabled}
      className={clsx(
        'border-1 data-[checked]:bg-congress-blue group relative inline-flex h-6 w-11 cursor-pointer items-center rounded-full border-transparent bg-gray-400 transition-colors duration-200 ease-in-out data-[disabled]:cursor-not-allowed focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2',
      )}
      disabled={disabled}
    >
      <span className="sr-only">Enable notifications</span>
      <span
        className={clsx(
          'inline-block size-4 translate-x-1 rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-6',
        )}
      />
    </Switch>
  ) : (
    <div className="relative inline-flex h-6 w-11 shrink-0 rounded-full border-2 border-transparent bg-gray-200">
      <span className="inline-block size-5 translate-x-0 rounded-full bg-white shadow ring-0" />
    </div>
  );
};
