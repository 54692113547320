import React from 'react';
import {
  ChevronRightIcon,
  EllipsisHorizontalIcon,
} from '@heroicons/react/20/solid';
import { Link } from 'react-router-dom';

import { Button } from '../Button/Button';

import {
  Breadcrumbs,
  PageHeadingButtons,
  TestResultDetailMenu,
} from '../../../utils/type';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { ErrorBoundaryWrapper } from '../../ErrorBoundary/ErrorBoundaryWrapper';

type Props = {
  breadcrumbs: Breadcrumbs[];
  pageTitle: string;
  pageHeadingButtons?: PageHeadingButtons[];
  testResultDetailMenu?: TestResultDetailMenu[];
};

export const PagingHeadings = ({
  breadcrumbs,
  pageTitle,
  pageHeadingButtons,
  testResultDetailMenu,
}: Props) => {
  return (
    <div>
      <ErrorBoundaryWrapper>
        <nav className="hidden sm:flex" aria-label="Breadcrumb">
          <ol role="list" className="flex items-center space-x-4">
            {breadcrumbs?.map((breadcrumb, bIndex) => (
              <li key={breadcrumb?.title}>
                <div className="flex">
                  {breadcrumb.href ? (
                    <Link
                      to={breadcrumb?.href}
                      className={`${
                        bIndex < breadcrumbs.length - 1 && 'mr-4'
                      } text-sm font-medium text-gray-500 hover:text-gray-700`}
                    >
                      {breadcrumb?.title}
                    </Link>
                  ) : (
                    <p
                      className={`${
                        bIndex < breadcrumbs.length - 1 && 'mr-4'
                      } text-sm font-medium text-gray-500`}
                    >
                      {breadcrumb.title}
                    </p>
                  )}
                  {bIndex < breadcrumbs?.length - 1 && (
                    <ChevronRightIcon
                      className="size-5 shrink-0 text-gray-400"
                      aria-hidden="true"
                    />
                  )}
                </div>
              </li>
            ))}
          </ol>
        </nav>
        <div className="mt-2 md:flex md:items-center md:justify-between">
          <div className="min-w-0 flex-1">
            <h1 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
              {pageTitle}
            </h1>
          </div>
          <div className="mt-4 flex shrink-0 gap-2 md:ml-4 md:mt-0">
            {pageHeadingButtons !== null &&
              pageHeadingButtons?.map((item, index) =>
                item?.href ? (
                  <Link to={item?.href} key={index}>
                    <Button
                      type={item?.type}
                      className={item?.type}
                      onClick={item?.onClick}
                      variant={item?.variant}
                      disabled={item?.disabled}
                      id={item?.id}
                      form={item?.form}
                    >
                      {item?.title}
                    </Button>
                  </Link>
                ) : (
                  <Button
                    type={item?.type}
                    key={index}
                    variant={item?.variant}
                    onClick={item?.onClick}
                    id={item?.id}
                    form={item?.form}
                    disabled={item?.disabled}
                  >
                    {item?.title}
                  </Button>
                ),
              )}
            {testResultDetailMenu && (
              <Menu>
                <MenuButton className="ml-4">
                  <EllipsisHorizontalIcon className="size-6" />
                </MenuButton>
                <MenuItems
                  anchor="bottom end"
                  className="z-20 mt-1 flex flex-col rounded border border-solid border-gray-200 bg-white shadow"
                >
                  {testResultDetailMenu.map((item) => (
                    <MenuItem key={item.title} disabled={item.disabled}>
                      <button
                        className="px-3 py-2 text-sm font-normal leading-normal text-black data-[focus]:bg-indigo-600 data-[disabled]:text-gray-300 data-[focus]:text-white"
                        type="button"
                        onClick={item.onClick}
                        disabled={item.disabled}
                      >
                        {item.title}
                      </button>
                    </MenuItem>
                  ))}
                </MenuItems>
              </Menu>
            )}
          </div>
        </div>
      </ErrorBoundaryWrapper>
    </div>
  );
};
