import React from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { ChevronLeftIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';
import { ErrorBoundaryWrapper } from '../ErrorBoundary/ErrorBoundaryWrapper';

type Props = {
  page: number;
  isDashboard?: boolean;
};

export const PaginationLeftButton = ({ page, isDashboard = true }: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const updatePreviousPageInQuery = () => {
    const newParams = new URLSearchParams(searchParams);

    page === 1
      ? newParams.set('page', String(1))
      : newParams.set('page', String(page - 1));

    setSearchParams(newParams);
  };

  const newSearchParams = new URLSearchParams(searchParams);
  page === 1
    ? newSearchParams.set('page', String(1))
    : newSearchParams.set('page', String(page - 1));

  return (
    <ErrorBoundaryWrapper>
      {isDashboard ? (
        <Link
          to={{ search: newSearchParams.toString() }}
          className={clsx(
            'relative inline-flex items-center rounded-l-md p-2 text-gray-400 ring-1 ring-inset ring-gray-300 focus:z-20 focus:outline-offset-0',
            page === 1 ? 'cursor-default' : 'hover:bg-gray-50',
          )}
        >
          <span className="sr-only">Previous</span>
          <ChevronLeftIcon className="size-5" aria-hidden="true" />
        </Link>
      ) : (
        <button
          type="button"
          className={clsx(
            'relative inline-flex items-center rounded-l-md p-2 text-gray-400 ring-1 ring-inset ring-gray-300 focus:z-20 focus:outline-offset-0',
            page === 1 ? 'cursor-default' : 'hover:bg-gray-50',
          )}
          onClick={updatePreviousPageInQuery}
        >
          <span className="sr-only">Previous</span>
          <ChevronLeftIcon className="size-5" aria-hidden="true" />
        </button>
      )}
    </ErrorBoundaryWrapper>
  );
};
