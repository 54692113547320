import { ClockIcon } from '@heroicons/react/24/outline';
import dayjs from 'dayjs';
import React from 'react';
import { ErrorBoundaryWrapper } from '../../ErrorBoundary/ErrorBoundaryWrapper';
import clsx from 'clsx';
type Props = {
  dateTime: string;
  disabled?: boolean;
};

export const DateInTable = ({ dateTime, disabled = false }: Props) => {
  return (
    <ErrorBoundaryWrapper>
      <div className={clsx({ 'text-gray-300': disabled })}>
        {dayjs(dateTime).isValid() ? dayjs(dateTime).format('YYYY-MM-DD') : '-'}
      </div>
      <div
        className={clsx(
          'mt-1 flex items-center justify-start gap-2',
          disabled ? 'text-gray-300' : 'text-gray-500',
        )}
      >
        {dayjs(dateTime).isValid() && (
          <>
            <ClockIcon strokeWidth={1.5} className="size-3.5" />
            <div>{dayjs(dateTime).format('HH:mm')}</div>
          </>
        )}
      </div>
    </ErrorBoundaryWrapper>
  );
};
