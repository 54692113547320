import React, { Fragment, ReactNode } from 'react';
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Transition,
} from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';
import { GrPowerReset } from 'react-icons/gr';
import { ErrorBoundaryWrapper } from '../../ErrorBoundary/ErrorBoundaryWrapper';
interface ListItem {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  state: string | ListItem | any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setState: React.Dispatch<React.SetStateAction<string | ListItem | any>>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  list: (ListItem | any)[];
  wordInButton?: string;
  savedValue?: string;
  wordInOptions?: string;
  disabled?: boolean;
  onClickButton?: React.MouseEventHandler<HTMLButtonElement>;
  isReset?: boolean;
  resetOnClick?: () => void;
  resetDisabled?: boolean;
  isNeededButtonGray?: boolean;
  selectedOptions?: ReactNode;
  placeholder?: string;
};

export const ListBoxHeadlessUI = ({
  state,
  setState,
  list,
  wordInButton,
  savedValue,
  wordInOptions,
  disabled,
  onClickButton,
  resetOnClick,
  selectedOptions,
  placeholder,
  isReset = false,
  isNeededButtonGray = false,
  resetDisabled = false,
}: Props) => {
  return (
    <ErrorBoundaryWrapper>
      <div className="flex w-full items-center">
        <Listbox value={state} onChange={setState} disabled={disabled}>
          {({ open }) => (
            <>
              <div
                className={clsx(
                  'relative',
                  isReset ? 'w-[calc(100%-2rem)]' : 'w-full',
                )}
              >
                <ListboxButton
                  className={clsx(
                    'relative w-full cursor-default py-1.5 pl-3 pr-10 text-left shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6',
                    disabled && isNeededButtonGray ? 'bg-gray-100' : 'bg-white',
                    isReset ? 'rounded-l-md' : 'rounded-md',
                    wordInButton ? 'text-gray-900' : 'text-gray-400',
                  )}
                  onClick={onClickButton}
                >
                  <span className="block truncate">
                    {typeof state === 'string' || typeof state === 'number'
                      ? state
                      : state?.[wordInButton ?? ''] ?? placeholder}
                  </span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronUpDownIcon
                      className="size-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                  {selectedOptions && selectedOptions}
                </ListboxButton>
                <Transition
                  show={open}
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <ListboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-y-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 scrollbar-thin focus:outline-none sm:text-sm">
                    {list?.length === 0 ? (
                      <div className="relative cursor-default select-none px-4 py-2 text-gray-700">
                        Not Founded
                      </div>
                    ) : (
                      list?.map((value, index) => (
                        <ListboxOption
                          key={index}
                          className={clsx(
                            'group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-indigo-600 data-[focus]:text-white',
                          )}
                          value={
                            savedValue &&
                            typeof value === 'object' &&
                            value !== null
                              ? value?.[savedValue]
                              : value
                          }
                        >
                          <span
                            className={clsx(
                              'block break-all font-normal group-data-[selected]:font-semibold',
                            )}
                          >
                            {typeof value === 'string' ||
                            typeof value === 'number'
                              ? value
                              : value?.[wordInOptions]}
                          </span>
                          <span
                            className={clsx(
                              'invisible absolute inset-y-0 right-0 flex items-center pr-4 text-indigo-600 group-data-[selected]:visible group-data-[focus]:text-white',
                            )}
                          >
                            <CheckIcon className="size-5" aria-hidden="true" />
                          </span>
                        </ListboxOption>
                      ))
                    )}
                  </ListboxOptions>
                </Transition>
              </div>
            </>
          )}
        </Listbox>
        {isReset && (
          <button
            type="button"
            className="flex h-9 w-8 items-center justify-center rounded-r-md border-y border-r border-solid border-gray-300 px-2 shadow-sm enabled:hover:bg-gray-50"
            onClick={resetOnClick}
            disabled={resetDisabled}
          >
            <GrPowerReset style={{ color: '#9CA3AF' }} />
          </button>
        )}
      </div>
    </ErrorBoundaryWrapper>
  );
};
