import clsx from 'clsx';
import React, { ButtonHTMLAttributes, useState } from 'react';
import { TooltipTop } from '../Tooltip/TooltipTop';

interface DetailSize {
  [key: number]: string;
}

interface DetailStyle {
  [key: number]: string;
}

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  size?: number;
  isHoverEnabled?: boolean;
}

export const Detail = ({
  size = 6,
  isHoverEnabled = true,
  ...attributes
}: Props) => {
  const detailSize: DetailSize = {
    5: 'w-5 h-5',
    6: 'w-6 h-6',
  };

  const detailStyle: DetailStyle = {
    5: 'border-r border-r-gray-200 pr-2',
    6: clsx(
      'p-1',
      attributes.disabled
        ? 'text-gray-300'
        : 'hover:text-congress-blue text-gray-900',
    ),
  };

  const [isMouseEnter, setIsMouseEnter] = useState(false);
  const showTooltip = isMouseEnter;
  const tooltipMessage = 'Detail';

  return (
    <button
      type="button"
      aria-label="navigate detail"
      className={clsx(detailStyle[size], showTooltip && 'relative')}
      {...attributes}
      onMouseEnter={() => setIsMouseEnter(true)}
      onMouseLeave={() => setIsMouseEnter(false)}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className={detailSize[size]}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m5.231 13.481L15 17.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v16.5c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9zm3.75 11.625a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z"
        />
      </svg>
      {showTooltip && isHoverEnabled && (
        <TooltipTop positionClass="icon-description bottom-full min-w-max mb-2 -right-2 after:left-[50%]">
          {tooltipMessage}
        </TooltipTop>
      )}
    </button>
  );
};
