import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';

interface ListItem {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any; // 여기서는 간단히 모든 문자열 키에 대해 any 타입을 허용합니다.
}

export interface SelectedItem {
  idx: number;
  dndIndex: number;
}

export const useCheckboxWithDnd = (
  lists: ListItem[],
  idxKey: string,
  dndKey: string,
) => {
  const [selectedArray, setSelectedArray] = useState<SelectedItem[]>([]);
  const { pathname } = useLocation();

  const resetSelectedArray = () => {
    setSelectedArray([]);
  };

  const selectAllArray = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (selectedArray.length > 0 && selectedArray.length < lists.length) {
      const allItems = lists.map((item) => ({
        idx: item[idxKey],
        dndIndex: item[dndKey],
      }));
      setSelectedArray(allItems);
      return;
    }

    const target = e.target;

    if (target.checked) {
      const allItems = lists.map((item) => ({
        idx: item[idxKey],
        dndIndex: item[dndKey],
      }));
      setSelectedArray(allItems);
    } else {
      setSelectedArray([]);
    }
  };

  const selectArray = (
    idx: number,
    dndIndex: number,
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const target = e.target;
    if (target.checked) {
      const newSelectedArray = [...selectedArray, { idx, dndIndex }];
      newSelectedArray.sort((a, b) => a.idx - b.idx);
      setSelectedArray(newSelectedArray);
    } else {
      setSelectedArray(
        selectedArray.filter((item) => item.dndIndex !== dndIndex),
      );
    }
  };

  useEffect(() => {
    setSelectedArray([]);
  }, [pathname]);
  return {
    resetSelectedArray,
    selectAllArray,
    selectArray,
    selectedArray,
    setSelectedArray,
  };
};
