import React from 'react';

import { childrenNode } from '../../utils/type';

export const TableHeaderContainer = ({ children }: childrenNode) => {
  return (
    <thead>
      <tr>{children}</tr>
    </thead>
  );
};
